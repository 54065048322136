<template>
  <div>
    <vue-date-picker v-model="date" type="datetime" :value-type="format" input-class="form-control" :lang="lang"></vue-date-picker>
    <input type="hidden" :name="name" :value="date" />
  </div>
</template>

<script>
import VueDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ja";

export default {
  components: {
    VueDatePicker
  }, 
  props: {
    initValue: {
      type: String,
      required: false,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: false
    }
  },
  data: function() {
    return {
      date: null,
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    };
  },
  mounted: function() {
    this.date = this.initValue;
  }
};
</script>