// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap'

import Vue from 'vue/dist/vue.esm';
import '../styles/application';

import DatePicker from '../src/utilities/Datepicker';

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
Vue.prototype.$swal = Swal;

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
Vue.prototype.$dayjs = dayjs;

import Axios from '../src/axios';
import Toasted from 'vue-toasted';
Vue.prototype.$axios = Axios;

import filters from '../src/filters';
for (let name in filters) {
  Vue.filter(name, filters[name]);
}

import VueDatePicker from "vue2-datepicker";
Vue.component('vue-date-picker', VueDatePicker);

import Loading from '../src/components/share/Loading'

document.addEventListener('DOMContentLoaded', () => {
  const app = document.getElementById("app");

  const vue = new Vue({
    el: "#app",
    components: {
      DatePicker,
      TruncateContent: () => import('../src/components/share/TruncateContent'),
      ChangeStatus: () => import('../src/components/reviews/ChangeStatus'),
      ReviewRating: () => import('../src/components/reviews/ReviewRating'),
      UsersIndexPage: () => import('../src/components/users'),
      ProductsIndexPage: () => import('../src/components/products/index'),
      ProductsNewPage: () => import('../src/components/products/new'),
      ProductsShowPage: () => import('../src/components/products/show'),
      ProductsEditPage: () => import('../src/components/products/edit'),
      NewNewsPage: () => import('../src/components/news/New'),
      EditNewsPage: () => import('../src/components/news/Edit'),
      IndexNewsPage: () => import('../src/components/news/index'),
      InquiriesIndexPage: () => import('../src/components/inquiries'),
      ArticlesIndexPage: () => import('../src/components/articles/index'),
      ArticlesNewPage: () => import('../src/components/articles/new'),
      ArticlesEditPage: () => import('../src/components/articles/edit'),
      CouponsNewPage: () => import('../src/components/coupons/new'),
      CouponsEditPage: () => import('../src/components/coupons/edit'),
      GroupCardPage: () => import('../src/components/dashboard/GroupCard'),
      DashboardsIndexPage: () => import('../src/components/dashboard/Index'),
      OrdersShowPage: () => import('../src/components/orders/Show'),
      Loading: Loading
    }
  });
  
  const toastOption = { 
    duration: 3000,
    theme: "bubble"
  };
  Vue.use(Toasted, toastOption);
});
